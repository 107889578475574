<template>
  <section class="see_logistics">
    <NavBar />
    <Header type="personalCenter" />
    <div class="content box">
      <div class="current_position mt30">当前位置：订单列表-查看物流</div>
      <div class="parcel_list mt30 f">
        <div class="parcel_item mr15" :class="{'selected': currentIndex === index}" v-for="(item, index) in parcelList" :key="item.id" @click="parcelItemClick(index)">
          {{item.parcelName}}
        </div>
      </div>
      <div class="logistics_info mt30">
        <div class="title">物流信息</div>
        <div class="info mt30 p015">
          <div>物流状态：<span>{{currentLogisticsInfo.deliverystatus | deliverystatus}}</span></div>
          <div>承运公司：{{currentLogisticsInfo.typename}}</div>
          <div>物流单号：{{currentLogisticsInfo.number}}</div>
          <div>商品件数：{{currentLogisticsInfo.bookNum}}件</div>
        </div>
        <div class="situation mt15 p15">
          <div class="situation_item frfsc" :class="{'mt50': index !== 0}" v-for="(item, index) in currentLogisticsInfo.list" :key="index">
            <div class="icon mr15" :class="!item.time ? 'zero' : currentLogisticsInfo.deliverystatus === 3 && index === 1 ? 'one' : 'two'">{{!item.time ? '收' : ''}}</div>
            <div class="text">
              <p class="address" :style="{'color': index > 1 ? '#999' : '#000'}">{{item.status}}</p>
              <p class="time mt5" v-if="item.time">{{item.time}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import NavBar from 'components/NavBar/index.vue';
  import Header from 'components/Header/index.vue';
  import {
    getLogisticsInfo
  } from 'api/personalCenter.js';

  export default {
    components: {
      NavBar,
      Header
    },

    created() {
      this.getLogisticsInfo(this.$route.params.orderNo, this.$route.params.orderType);
    },

    data() {
      return {
        parcelList: [],
        currentIndex: 0,
        logisticsInfo: [],
        currentLogisticsInfo: {}
      }
    },

    filters: {
      deliverystatus(deliverystatus) {
        switch(deliverystatus) {
          case 1:
            return '运输中';
          case 2:
            return '派送中';
          case 3:
            return '已签收';
          case 4:
            return '派送失败';
        }
      }
    },

    methods: {
      getLogisticsInfo(orderNo, orderType) {
        getLogisticsInfo({
          orderNo,
					orderType
        }).then(res => {
          if(res.code) {
            this.$message.error(res.message);
            return;
          }
          res.data.forEach((item, index) => {
            this.parcelList.push({
              id: index,
              parcelName: `${index + 1}号包裹`
            });
            item.routeInfo.list.unshift({
              status: '收货地址：' + item.linkAddress,
              time: ''
            });
            this.logisticsInfo.push(item.routeInfo);
            this.currentLogisticsInfo = this.logisticsInfo[0];
          });
        });
      },
      parcelItemClick(index) {
        this.currentIndex = index;
        this.currentLogisticsInfo = this.logisticsInfo[this.currentIndex];
      }
    }
  }
</script>

<style lang="less" scoped>
  .see_logistics {
    .content {
      .current_position {
        font-size: var(--fontSize16);
        color: var(--color000);
      }
      .parcel_list {
        .parcel_item {
          width: 120px;
          height: 40px;
          background: var(--colorfff);
          border: 1px solid var(--colorccc);
          border-radius: 8px;
          font-size: var(--fontSize16);
          color: var(--color333);
          font-weight: 500;
          text-align: center;
          line-height: 38px;
          cursor: pointer;
        }
        .selected {
          background: var(--colorfffbf7);
          border: 1px solid var(--colored6d00);
          color: var(--colored6d00);
        }
      }
      .logistics_info {
        .title {
          font-size: var(--fontSize20);
          color: var(--color000);
          font-weight: bold;
        }
        .info {
          background: var(--colorfbfbfb);
          border: 1px solid var(--colorccc);
          div {
            margin: 15px 0;
            font-size: var(--fontSize12);
            color: var(--color000);
            span {
              color: var(--colored6d00);
            }
          }
        }
        .situation {
          background: var(--colorfff);
          border: 1px solid var(--colorccc);
          .situation_item {
            .icon {

            }
            .text {
              flex: 1;
              .address {
                font-size: var(--fontSize16);
                color: var(--color000);
                font-weight: bold;
              }
              .time {
                font-size: var(--fontSize12);
                color: var(--color999);
              }
            }
            .zero {
              width: 30px;
              height: 30px;
              background: var(--colorfe1818);
              border-radius: 50%;
              font-size: var(--fontSize16);
              color: var(--colorfff);
              font-weight: bold;
              text-align: center;
              line-height: 30px;
            }
            .one {
              width: 30px;
              height: 30px;
              border: 8px solid #ffe2e2;
              border-radius: 50%;
              background: #FE1818;
            }
            .two {
              margin-left: 9px;
              width: 12px;
              height: 12px;
              background: var(--colord8d8d8);
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
</style>
